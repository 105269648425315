
import * as Components from "./Frontierwok"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "frontierwok"
}

