import React, { Component, PureComponent } from 'react';
import {NavLink} from 'react-router-dom'
import Form from './Form'

import './index.scss'


import Catering from './Catering'

export const pages = [
  {path: "/", label: "Order"},
  {path: "/catering", label: "Catering", component: Catering},
]

export class SiteNav extends PureComponent {
  render() {
    const pages = [
      {path: "/", label: "Order"},
      {path: "/catering", label: "Catering"},
    ]
    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external}) => (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        ))}
      </ul>
    </nav>
  )}
}



export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">
          {/* <div className="notice">
            <p>We are Open 11am to 10pm</p>
            <p>Sign up :</p>
            <Form></Form>

            <p>Questions? <a href="mailto:frontierwok@afoodapart.com">frontierwok@afoodapart.com</a></p>

          </div> */}
          <div className="text">
            <p>NOW OPEN TUESDAYS!</p>
            <p>Mon-Fri: 11am to 9pm</p>
            <p>Sat,Sun: 12pm to 9pm</p>
            {/* <p>Closed on Tuesdays</p> */}
            <p>Order Togo and Delivery Below:</p>

          </div>


        </div>
      </div>
    );
  }
}


export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isLunch = (
    (hours >= 22) && (minutes >= 0) && (hours < 23)
  ) || (
    (hours >= 0) && (hours < 15)
  )
  const showLunchMenu = isLunch

  const isLunchSubmenu = tag.indexOf('lunch') !== -1
  const isDinnerSubmenu = tag.indexOf('dinner') !== -1

  

  if (isLunchSubmenu) { 
    if (showLunchMenu) {
      return true 
    } else {
      return false
    }
  }

  if (isDinnerSubmenu) { 
    if (!showLunchMenu) {
      return true 
    } else {
      return false
    }
    
  }

  return true
}
